import React from 'react';

const Invalid = (props) => {
    return (
        <div className="text-center text-white">
            <h1>Ups!</h1>
            <p className="mt-5">Lo sentimos, el link al que has ingresado { !props.token ? 'no es válido' : 'ha caducado' }.</p>
            <p>Por favor, realiza una nueva solicitud.</p>
        </div>
    )
};

export default Invalid;