import axios from 'axios';

const changePass = async (password) => {
    try {
        const body = {password};
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/users/forgot/changePassword`, body);
        return res.data;
    } catch (err) {
        return err.response;
    }
};

export default changePass;