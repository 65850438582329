import axios from 'axios';
import setAuthorizationToken from '../utils/setAuthToken';

const validate = async (token) => {
    setAuthorizationToken(token);

    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/users/forgot/validate`);
        return res.data;
    } catch (err) {
        return err.response;
    }
};

export default validate;