/* eslint-disable react/jsx-no-target-blank */
import React, {useState, useEffect} from 'react';
import {withRouter} from 'react-router-dom';
import queryString from 'query-string';
import { CSSTransition } from 'react-transition-group';
// COMPONENTS
import Forgot from './components/Forgot';
import Invalid from './components/Invalid';
import Loader from './components/Loader';
// SERVICES
import Validation from './services/validate';

const App = (props) => {
  const { token } = queryString.parse(props.location.search);
  const [isValid, setValid] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
   // validate token
   const validate = async (token) => {
    const res = await Validation(token);

    if (!res) {
      setValid(false);
      setLoading(false);
      return false;
    }

    if (res.success) {
      setValid(true);
      setLoading(false);
    } else {
      setValid(false);
      setLoading(false);
    }
   }

   validate(token);
  }, [token]);

  return (
    <div>
      <CSSTransition
          in={loading}
          timeout={300}
          classNames="example"
          unmountOnExit={true}
          mountOnEnter={true}
        >
          <Loader />
      </CSSTransition>
      <CSSTransition
          in={!loading}
          timeout={300}
          classNames="example"
          unmountOnExit={false}
          mountOnEnter={true}
        >
        <div className="container-fluid align-items-center justify-content-center w-auto d-flex my-5">
          <div className="row align-items-center w-100">
              <div className="col-xl-4 col-lg-6 col-12 mx-auto p-5 rounded bg-gray">
                {
                  isValid
                  ?
                  <Forgot />
                  :
                <Invalid token={token} />
                }
              </div>
              <div className="col-12 text-center my-5">
                <p className="copyright">&copy; Copyright 2020 - <a href="http://www.reachout.global/" className="reachout-link" target="_blank">ReachOut.global</a>{ process.env.REACT_APP_VERSION && ` - Version ${process.env.REACT_APP_VERSION}` }</p>
              </div>
          </div>
        </div>
      </CSSTransition>
      </div>
  );
}

export default withRouter(App);
