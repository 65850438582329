import React, {useState} from 'react';
import changePass from '../services/changePass';
import Loader from './Loader';

const Forgot = (props) => {
    const [pass1, setPass1] = useState(null);
    const [pass2, setPass2] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const change = async () => {
        if (!pass1) {
            return setError('Por favor, ingresá una nueva contraseña.');
        }

        if (pass1.length < 6) {
            return setError('La contraseña ingresada debe tener al menos 6 caracteres.');
        }

        if (pass1.length > 40) {
            return setError('Lo sentimos, la contraseña es demasiado larga.');
        }

        if (!pass2) {
            return setError('Por favor, ingresá nuevamente la contraseña.');
        }

        if (pass1 !== pass2) {
            return setError('Las contraseñas no coinciden.');
        }

        try {
            setLoading(true);
            setError(false);

            const result = await changePass(pass1);

            if (result) {
                setLoading(false);
                return setSuccess(true);
            } else {
                return setError('Lo sentimos, no podemos cambiar tu contraseña en este momento.');
            }
        } catch (error) {
            return setError('Lo sentimos, no podemos cambiar tu contraseña en este momento.');
        }
    }

    return (
        <div>
            {
                loading
                ?
                <Loader partial={true} />
                :
                (
                    success
                    ?
                    <span>
                        <h1 className="text-center text-white">Listo!</h1>
                        <p className="text-center text-white">Tu contraseña fue actualizada correctamente.</p>
                    </span>
                    :
                    <span>
                        <h1 className="text-center text-white">Reseteá tu contraseña</h1>
                        <div className="input-group mt-5">
                            <input type="password" className="form-control" defaultValue={pass1} onChange={(event) => setPass1(event.target.value)} placeholder="Nueva contraseña" aria-label="Nueva contraseña" />
                        </div>
                        <div className="input-group my-3">
                            <input type="password" className="form-control" defaultValue={pass2} onChange={(event) => setPass2(event.target.value)} placeholder="Repetí la contraseña" aria-label="Repetí la contraseña" />
                        </div>
                        <p className={`text-danger text-center error-container ${!error && 'hidden'}`}>{error}</p>
                        <div className="input-group mt-3 mb-4">
                            <button className="btn btn-light w-100" onClick={change}>Cambiar contraseña</button>
                        </div>
                    </span>
                )
            }
        </div>
    )
};

export default Forgot;